import React, { Component } from 'react'

export class StandardProducts extends Component {
    render() {
        return (



            <div>
            <section class="section gray-bg" >
            <div class="container">
                <div class="row section-title md-m-25px-b m-45px-b justify-content-center text-center" >
                    <div class="col-lg-8" >
                        <label class="theme-bg-alt p-15px-lr p-5px-tb font-small border-radius-15 theme-color">Our Products</label>
                        <h3 class="display-4 dark-color m-20px-b">Standard Bearings</h3>
                        <div class="lead">Following is the list of Standard bearings we offer</div>
                    </div>
                </div>
                <div class="row">
         
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Deep Groove Ball Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Deep Grove Ball bearings</h6>
                        </div>

                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Angular Contact Ball Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Angular Contact Ball Bearings</h6>
                        </div>
                        
                        
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Double Row Angular Contact Ball Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Double Row Angular Contact Ball Bearing</h6>
                        </div>

                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Self Aligning Ball Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Self Aligning Ball Bearing</h6>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                            <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                                <div>
                                    <img src="java\img\bearings\Products\Standard\Thrust Ball Bearings.png" title="" alt=""/>
                                </div>
                                <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Thrust Ball Bearings</h6>
                        </div>
                      
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                         <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Cylindrical Roller Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Cylindrical Roller Bearing</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Tapered Roller Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Tapered Roller Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Spherical Roller Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Spherical Roller Bearings</h6>
                        </div>
                      
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Rod Ends & Plain Spherical Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Rod Ends and Plain Spherical Bearings</h6>
                        </div>

                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Spherical Thrust Roller Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Spherical Thrust Roller Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Needle roller bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Needle roller bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Unit Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Unit Bearings</h6>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Plumber Blocks.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Plumber blocks</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Bearing Accessories.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Bearing Accessories</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                    <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Standard\Allied Products.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Allied Products</h6>
                        </div>          
                    </div>
                   

                </div>
            </div>
        </section>
            </div>
        )
    }
}

export default StandardProducts
