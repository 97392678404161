import firebase from 'firebase';

var config = {
    apiKey: "AIzaSyAC2jqDepoR_g1lgjhaQbxr90Fdrz-eP5g",
    authDomain: "snr-bearings.firebaseapp.com",
    databaseURL: "https://snr-bearings.firebaseio.com",
    projectId: "snr-bearings",
    storageBucket: "snr-bearings.appspot.com",
    messagingSenderId: "335873421435",
    appId: "1:335873421435:web:73dc5ea20f0d04ef1932de",
    measurementId: "G-CB01N41K53"
  };
const fire = firebase.initializeApp(config);
export default  fire