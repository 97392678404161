import React, { Component } from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export class MissionAndVision extends Component {
    render() {
        return (
            <div>
            <section class="section gray-bg">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-5 m-15px-tb">
                        <h2 class="h1 font-w-700 dark-color m-15px-b">Our Mission  <a href="/" title="Momb" class="logo">
                        <img style={{width:'90px',height:'50px'}} src="java\img\bearings\MIssion transparent.png" title="" alt=""/>
                        </a></h2>
                       
                    
                        <p class="lead m-25px-b">We endeavour to become one of the most reliable bearing solution provider and to create more possibilities by:
                        <div class="p-35px md-p-25px box-shadow light-bg border-radius-5 m-30px-b">
                           
                            <ul class="list-type2 m-0px p-0px">
                                <li class="m-10px-t">Quality (our priority) </li>
                                <li class="m-10px-t">Service (flexibility and efficiency) </li>
                                <li class="m-10px-t">Price (competitive) </li>
                            </ul>
                        </div> 
                             </p>
                        <a class="m-btn m-btn-theme m-btn-radius"  style={{backgroundColor:'maroon'}} href="ContactUs">Learn More</a>
                    </div>

                    <div class="col-lg-5 m-15px-tb">
                        <h2 class="h1 font-w-700 dark-color m-15px-b">Our Vision  <a href="/" title="Momb" class="logo">
                        <img style={{width:'90px',height:'50px'}} src="java\img\bearings\Vision transparent.png" title="" alt=""/>
                        </a></h2>  
                        <p class="lead m-25px-b">We envision SNR Bearings as a thriving and an innovative bearing solution provider through partnering with industries and setting high benchmarks in the Indian Bearing Industry.
                        <div class="p-35px md-p-25px box-shadow light-bg border-radius-5 m-30px-b">
                           
                            <ul class="list-type2 m-0px p-0px">
                                <li class="m-10px-t">Customer Satisfaction (Top priority) </li>
                                <li class="m-10px-t">Customer Service </li>
                              
                            </ul>
                        </div> 
                             </p>
                        <a class="m-btn m-btn-theme m-btn-radius"  style={{backgroundColor:'maroon'}} href="ContactUs">Learn More</a>
                    </div>

           

                </div>
            </div>
        </section>
        <section class="gray-bg" >
                <div class="container">
                <div class="row section-title m-30px-b md-m-20px-b justify-content-center text-center">
                    <div class="col-lg-7">
                        <label style={{width:'300px',height:'30px',color:'white',fontSize:'20px',backgroundColor:'maroon'}} class="theme-bg-alt p-15px-lr p-5px-tb font-small border-radius-15 theme-color">Message From Our Leaders</label>
                    </div>
                </div>
                    <Carousel autoPlay>
                        <div>
                        <img alt="" src="java\img\bearings\SMG.png" />
                        
                        </div>
                        <div>
                        <img alt=""  src="java\img\bearings\Rnk Message.png"/>
                        </div>
                    </Carousel>
                </div>
        </section>
            </div>
        )
    }
}

export default MissionAndVision


