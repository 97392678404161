import React, { Component } from 'react'
import fire from '../Config'
import firebase from 'firebase/app'

export class ContactUs extends Component {
    constructor(props){
        super(props);
        this.state={
         name:'',
         email:'',
         subject:'',
         phone:'',
         query:''
        }
        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeQuery = this.handleChangeQuery.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
        this.login = this.login.bind(this);
      }

      login(e) {
       
        if(this.state.name == ""){
            alert("Please fill in valid details")
        }
        else{ 
            alert("Your data has been recorded. We will get back to you within 24 hrs. Thank you for choosing us.")
            var db = firebase.firestore();
            db.collection("users").doc(this.state.name).set({
                name:this.state.name,
                phone:this.state.phone,
                email:this.state.email,
                subject:this.state.subject,
                query:this.state.query
              }).then(function() {
                console.log("Data Ojbect for new User created");
              });  
  
              this.setState({
                name:"",
                phone:'',
                email:'',
                subject:"",
                query:""
              })}
        
        }
      handleSubmit(){
        alert("Please try again later" )
        var db = firebase.firestore();
        db.collection("users").doc(this.state.name).set({
            name:this.state.name,
            phone:this.state.phone,
            email:this.state.email,
            subject:this.state.subject,
            query:this.state.query
          }).then(function() {
            console.log("Data Ojbect for new User created");
          }); 

          this.setState({
              name:'',
              phone:''
          })
      }

      handleChangeName(e){
        this.setState({name:e.target.value});
    }
    handleChangeEmail(e){
        this.setState({email:e.target.value});
    }
    handleChangePhone(e){
        this.setState({phone:e.target.value});
    }
    handleChangeSubject(e){
        this.setState({subject:e.target.value});
    }
    handleChangeQuery(e){
        this.setState({query:e.target.value});
    }
    render() {
        return (
            <div>
            <main>
       
        <section class="section"  style={{"backgroundImage":`url(java/img/ContactUs.jpg)`}}>
            <div class="container">
                <div class="row justify-content-center" >
                    <div class="col-lg-8 text-center">
                        <h1 style={{backgroundColor:'white',color:'maroon',width:'350px'}} class="h1 font-w-1200 dark-color">Got a Question?</h1>
                        <p style={{backgroundColor:'white',color:'maroon'}} class="h4 dark-color">We have got you covered...Try contacting us / Visiting us or give us your contact and we will call you back!</p>
                    </div>
                </div>
            </div>
        </section>
    
        <section class="section gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="card h-100 box-shadow">
                            <div class="card-body text-center">
                                <div class="icon-lg theme-bg white-color border-radius-5 d-inline-block">
                                    <i style={{color:'white'}} class="fas fa-map-signs"></i>
                                </div>
                                <h5 class="m-20px-t font-w-700 dark-color">Address</h5>
                                <p class="m-0px">Suite No 21,<br/>Banda House, 8/16, Narayan Dhuru Cross Lane, Mumbai 400 003</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="card h-100 box-shadow">
                            <div class="card-body text-center">
                                <div class="icon-lg theme-bg white-color border-radius-5 d-inline-block">
                                    <i style={{color:'white'}} class="fas fa-envelope"></i>
                                </div>
                                <h5 class="m-20px-t font-w-700 dark-color">Email</h5>
                                <p class="m-0px">sampat@snrbrgs.com <br/>

snrbrgs@gmail.com </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="card h-100 box-shadow">
                            <div class="card-body text-center">
                                <div class="icon-lg theme-bg white-color border-radius-5 d-inline-block">
                                    <i style={{color:'white'}} class="fas fa-phone-alt"></i>
                                </div>
                                <h5 class="m-20px-t font-w-700 dark-color">Mobile</h5>
                                <p class="m-0px">
                                +9821217576</p>
                                <p class="m-0px">
                                +9833488933 
                                </p>
                    
                             
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="card h-100 box-shadow">
                            <div class="card-body text-center">
                                <div class="icon-lg theme-bg white-color border-radius-5 d-inline-block">
                                    <i style={{color:'white'}} class="fas fa-fax"></i>
                                </div>
                                <h5 class="m-20px-t font-w-700 dark-color">Landline</h5>
                                <p class="m-0px">   +022-23451603 </p>
                                <p class="m-0px">
                                +022-22136153 </p>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </section>
      
        <section class="section" id="applynow">
            <div class="container">
                <div class="row section-title justify-content-center text-center md-m-25px-b m-45px-b">
                    <div class="col-lg-7">
                        <label class="theme-bg-alt p-15px-lr p-5px-tb font-small border-radius-15 theme-color">Contact Us</label>
                        <h3 class="h1 font-w-700 dark-color m-10px-b">Tell us about yourself</h3>
                        <p class="lead">Share your details and we will get back to you.</p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-11 col-lg-9">
                        <div class="card">
                            <div class="card-body">
                                <div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="dark-color font-w-700">Full Name</label>
                                                <input  onChange={this.handleChangeName} value = {this.state.name} type="text" class="form-control" placeholder="Full Name"/>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="dark-color font-w-700">Your Email</label>
                                                <input onChange={this.handleChangeEmail} value = {this.state.email} type="text" class="form-control" placeholder="info@domain.com"/>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="dark-color font-w-700">Subject</label>
                                                <input  onChange={this.handleChangeSubject} value = {this.state.subject} type="text" class="form-control" placeholder="Potential Buyer"/>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="dark-color font-w-700">Phone</label>
                                                <input onChange={this.handleChangePhone} value = {this.state.phone} type="text" class="form-control" placeholder="+014 85652355"/>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="dark-color font-w-700">How Can We Help You?</label>
                                                <textarea onChange={this.handleChangeQuery} value = {this.state.query} class="form-control" rows="6" name="answer" placeholder="Hello, There! " aria-label="How'd you hear about Front?" required="" data-msg="Please enter an answer." data-error-class="u-has-error" data-success-class="u-has-success"></textarea>
                                            </div>
                                        </div>

                                        <button class="m-btn m-btn-theme m-btn-radius"  onClick={this.login} style={{backgroundColor:'maroon',marginLeft:'20px'}} >Contact</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
        {/* <section class="section" id="applynow">
            <div class="container">
                <div class="row section-title justify-content-center text-center md-m-25px-b m-45px-b">
                    <h3>Visit us here<br/><hr/></h3>
                        <GoogleMaps
        apiKey={"AIzaSyCT-jRtPJqNbV9NhKzHhoHSpWe6SVv3rMo"}
        style={{height: "400px", width: "100%"}}
        zoom={15}
        center={{lat: 18.951600, lng: 72.833250}}
        markers={{lat: 18.951600, lng: 72.833250}} //optional
        />
                </div>
            </div>
        </section> */}

    </main>
            </div>
        )
    }
}

export default ContactUs
