import React from 'react';
import './App.css';
import Home from './Pages/Home'
import Header from './Components/Header'
import Footer from './Components/Footer'
import ContactUs from './Pages/ContactUs'
import NonStandardProducts from './Pages/NonStandardProducts'
import StandardProducts from './Pages/StandardProducts'
import AbousUs from './Pages/AbousUs'
import Services from './Pages/Services'
import MissionAndVision from './Pages/MissionAndVision'
import { HashRouter, Route, Link,Switch, BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="App">
    <Header/>
                   
            <BrowserRouter basename="/snr-bearings">
                <Route exact  path="/" component = {Home}/>
                <Route exact  path="/Home" component = {Home}/>
                  <Route exact  path="/StandardProducts" exact render={props => <StandardProducts/>}/>
             
                  <Route exact  path="/ContactUs" >
                  <ContactUs/>
                  </Route>
                  <Route exact  path="/NonStandardProducts"  component = {NonStandardProducts}/>
                  <Route exact  path="/AboutUs"  component={AbousUs}/>
                  <Route exact  path= "/Services"  component={Services}/>
                  <Route  exact path= "/Mission&Vision" component={MissionAndVision}/>
            </BrowserRouter>
            
      <Footer/>
    </div>
  );
}


export default App;