import React, { Component } from 'react'

export class Home extends Component {
    render() {
        return (
            <div>
    <main>
      
        <section class="bg-center bg-cover bg-fiexd effect-section" style={{"backgroundImage":`url(java/img/Picture1.png)`}}>
            <div class="effect-round bottom">
                <img class="svg_img white-color" src="java\img\effect\bottom-effect.svg" title="" alt=""/>
            </div>

            <div class="container">
                <div class="row full-screen align-items-center justify-content-center p-100px-tb">
                    <div class="col-lg-8 text-center m-50px-t">
                        <h1 class="white-color display-4 m-15px-b">Welcome to SNR-Bearings</h1>
                        <p class="lead white-color font-w-400"><i style={{"textDecoration":`bold`}}>A Complete Friction Solution Provider</i>

             </p>
                        <div class="p-5px-t m-btn-dual">
                            <a style={{backgroundColor:'maroon'}} class="m-btn m-btn-theme m-btn-radius" href="ContactUs">Lets Start</a>
                            <a class="m-btn m-btn-light m-btn-radius" href="AboutUs">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 
        <section class="section border-bottom-1 border-color-gray">
            <div class="container">
                <div class="row section-title md-m-25px-b m-45px-b justify-content-center text-center">
                    <div class="col-lg-8">
                        <h3 class="display-4 dark-color m-10px-b">Do more with SNR</h3>
                        <div class="lead font-w-400">We  aspire to continuously contribute to the development of our society by supporting the technical innovation to worldwide original equipment manufacturers(OEMs).</div>
                    </div>
                </div>

                <div class="row">
 

                    <div class="col-md-4 m-15px-tb">
                        <div class="card card-body box-shadow-hover transition hover-top">
                            <div class="icon-lg border-radius-5  theme-bg-alt theme-color">
                                <i style={{color:'maroon'}} class="fab fa-angular"></i>
                            </div>
                            <h5 class="dark-color font-w-700 p-25px-t m-10px-b">Products & Technologies</h5>
                            <p class="m-15px-b">We are not only dedicated to meeting the diversified needs of our customers, but also offering a wide range of high quality products and providing our best solutions to our customers. </p>
                            <a style={{color    :'maroon'}} class="font-w-700" href="AboutUs">Learn More</a>
                        </div>
                    </div>

                    <div class="col-md-4 m-15px-tb">
                        <div class="card card-body box-shadow-hover transition hover-top">
                            <div class="icon-lg border-radius-5  theme-bg-alt theme-color">
                                <i style={{color:'maroon'}} class="fab fa-adobe"></i>
                            </div>
                            <h5 class="dark-color font-w-700 p-25px-t m-10px-b">Network</h5>
                            <p class="m-15px-b">With our dealer network strategically located all over India, having well trained engineers & sales personnel, we offer service oriented supplies to get maximum satisfaction to the end users. </p>
                            <a style={{color    :'maroon'}} class="font-w-700" href="AboutUs">Learn More</a>
                        </div>
                    </div>

                    <div class="col-md-4 m-15px-tb">
                        <div class="card card-body box-shadow-hover transition hover-top">
                            <div class="icon-lg border-radius-5  theme-bg-alt theme-color" style={{color:''}}>
                                <i style={{color:'maroon'}} class="fas fa-apple-alt"></i>
                            </div>
                            <h5 class="dark-color font-w-700 p-25px-t m-10px-b">Inventory</h5>
                            <p class="m-15px-b"> We maintain a huge inventory at our warehouses which are geographically situated to enable proximity to all parts of India. This also enables us to cater to clients with huge demands. </p>
                            <a style={{color    :'maroon'}} class="font-w-700" href="AboutUs">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section white-bg">
            <div class="container">
                <div class="row section-title md-m-25px-b m-45px-b justify-content-center text-center">
                    <div class="col-lg-8">
                       
                        <h3 class="display-4 dark-color m-15px-b">Brands We Deal In:</h3>
                        
                    </div>
                </div>
                <div>
                    <div >
                        <div class="border-radius-5 box-shadow">
                            <div class="row">
                            <div class="col-lg-5 m-15px-tb">
                            <img class="rounded" style ={{marginBottom:'10px',marginLeft:'20px'}} src="java\img\bearings\SYN_LOGO.jpg" title="Our Connections" alt=""/>
                            <p style={{marginLeft:'15px',marginRight:'10px',textAlign:'justify'}}>Combining decades-long experience with the latest technical advancements, the SYN engineering team is continuously optimising the existing products as well as developing new ones. SYN Bearings can provide you with rapid, practical and creative custom-made solutions. Their experienced team has developed special bearings and systems for numerous industries viz. food processing equipment, paper converting machinery, pumps, electric motors, gearboxes, and auxiliary power units, to name a few. SYN ensures customers satisfaction with its before and after sales support and its technical engineering team assures on-site as well as online technical support for the customers. </p>
                            </div>
                            <div class="col-lg-6 m-15px-tb">
                            <a href ='http://www.ubc-bearing.com/'>    <img   style ={{marginBottom:'10px',marginLeft:'20px'}} class="rounded" src="java\img\bearings\UBC_LOGO.png" title="Our Connections" alt=""/></a>
                            <p style={{marginLeft:'10px',marginRight:'10px',textAlign:'justify'}}>UBC is a subsidiary of IKO Nippon Thompson Group of Japan, a world’s leading manufacturer of Needle Roller Bearings and Linear Motion Rolling Guides and Components. Making the most of IKO’s know-how and its resources, UBC has significantly boosted its performance, brand awareness and recognition worldwide. UBC is a manufacturer of wide range of bearings. UBC products are the perfect alternative for customers who intend to reduce costs without compromising bearing performance. Nippon Thomson Group with its own reliable brands IKO, UBC & AXPB are well accepted and trusted worldwide. </p>
                                </div>
                            </div>
 
   
                        
                        </div>
                    </div>
                    <div >
                      

    
                    </div>
                </div>
            </div>
        </section>
 

   
     
 
    </main>

            </div>
        )
    }
}

export default Home
