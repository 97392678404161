import React, { Component } from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export class AbousUs extends Component {
    render() {
        return (
            <div>

            <section class="section light-bg">
            <div class="container">
                <div class="row section-title m-30px-b md-m-20px-b justify-content-center text-center">
                    <div class="col-lg-8">
                        <label class="font-w-700 m-10px-b theme-color dark-color ">Why Choose us ?
                        </label>
                        <h3 class="display-4 dark-color m-20px-b dark-color ">Greatly simplified workflow</h3>
                        <div class="lead ">A strong customer-centric approach and constant quest for top class quality and service have enabled us to achieve the most trusted and most reliable firm in the bearing industry.</div>
                    </div>
                </div>
                <div class="row justify-content-around align-items-center m-35px-b">
                    <div class="col-md-10 col-lg-8 m-15px-tb">
                        <div class="border-radius-5 box-shadow">
                            <img class="rounded" src="java\img\bearings\Chart.png" title="" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
          



            <section class="section">
            <div class="container">
                <div class="row section-title m-40px-b justify-content-center text-center">
                    <div class="col-lg-7">
                        <label class="theme-bg-alt p-15px-lr p-5px-tb font-small border-radius-15 theme-color">Help You!</label>
                        <h3 class="h1 font-w-700 dark-color m-10px-b">FAQ's</h3>
                        <div>Here are some of the frequently asked questions.</div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="accordion accordion-01">
                            <div class="acco-group white-bg">
                                <a href="#" class="acco-heading">Related to quality of our products</a>
                                <div class="acco-des" >At SNR Bearings, it has been our endeavour to get you the Best Quality products at Better Price levels. Our firm’s founder and owner, Mr. Sampatmal Gouthi, under his leadership and his vision have led this firm to achieve a commendable position in this industry.</div>
                            </div>
                            <div class="acco-group white-bg">
                                <a  class="acco-heading">Products range?</a>
                                <div class="acco-des" >It is possible for SNR Bearings to explore and excel in innovations and every new developments in this industry and thereby offer a wide range of products at our customer’s disposal.</div>
                            </div>
                            <div class="acco-group white-bg">
                                <a class="acco-heading">Why choose SNR?</a>
                                <div class="acco-des" >The heart of SNR Bearings is our ability to work with our customers on unique designs and applications. SNR can supply special and odd sized bearings including those with integral shafts.</div>
                            </div>
                            <div class="acco-group white-bg">
                                <a class="acco-heading">Do you offer technical assistance?</a>
                                <div class="acco-des">We have a dedicated technical team who are highly skilled and potential to cater to machining solutions related to bearings right from selection to its mounting techniques, from lubrication and maintenance to its dismounting techniques.</div>
                            </div>
                            <div class="acco-group white-bg">
                                <a class="acco-heading">Do you provide inspection service?</a>
                                <div class="acco-des" >We also conduct inspections to bearing failures for our end users and arrange seminars on causes and counter measures to premature bearing failures. .</div>
                            </div>
                            <div class="acco-group white-bg">
                                <a  class="acco-heading">What industries do you cater to?</a>
                                <div class="acco-des">We are already serving to several industries in India through our dealer networks and some of the industries we deal with are: 
Steel Industries ,
Paper Industries ,
Gearbox Industries , 
Thermal & Hydro Industries ,
Sugar Industries ,
Cement Industries ,
Mining Industries ,
Textile Industries ,
Chemical Industries ,
Railway ,
SNR Bearings currently has a clientele base of over 650 which are on a constant increase. </div>
                            </div>
                            

                        </div>
                        <div class=" text-center p-20px-t links-white">
                            <span style={{backgroundColor:'maroon'}} class="font-small box-shadow p-10px-tb p-15px-lr border-radius-5 theme-bg white-color">Still have questions? <a class="font-w-700" href="ContactUs">Contact Us</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class= "section">
                <div class="container" >
                <div class="row section-title m-30px-b md-m-20px-b justify-content-center text-center">
                    <div class="col-lg-7">
                        <label class="theme-bg-alt p-15px-lr p-5px-tb font-small border-radius-15 theme-color">Our clientele</label>
                    </div>
                </div>
                    <Carousel style={{ width: '80%',
    height: '0px'}} autoPlay>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\ACC.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Aditya BIrla.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Arvind.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\BMM.jpg" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Donear.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\ESSAR.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\JCT.jpg" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Jindal.jpg" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\LT.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Mahendra Group.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Nakoda.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Rail Wheel Factory.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\SAIL.png" />
                        </div>
        
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\SCCL.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\tnpl.jpg" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Transformers.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Vardhman.png" />
                        </div>
                        <div>
                        <img alt="" src="java\img\bearings\Clientele\Welspun.jpg" />
                        </div>

                        
                    </Carousel>
                </div>
        </section>
            </div>
        )
    }
}

export default AbousUs
