import React, { Component } from 'react'


export class Header extends Component {
    render() {
        return (
    <header class="header-nav header-maroon fixed-header" >
        <div class="fixed-header-bar">
            <div class="container">
                <div class="navbar navbar-default navbar-expand-lg main-navbar">
                    <div class="">
                        <a href="Home" title="SNR Bearings" class="logo">
                        <img style={{width:'100px',height:'70px'}} src="java\img\bearings\SNR LOGO.png" title="" alt=""/>
                        </a>
                    </div>

                    <div class="navbar-collapse justify-content-end collapse" id="navbar-collapse-toggle">
                   
                        <ul class="nav navbar-nav">
                            <li>
                                <a style={{color:'black'}} href="Home">Home</a>
                                <i></i>
                            </li>
                            <li class="mm-in px-mega">
                                <a style={{color:'black'}} href="javascript:void(0)">More</a>
                                <i class="fa fa-angle-down px-nav-toggle"></i>
                                <div class="px-mega-menu mm-dorp-in">
                                    <div class="row no-gutters">
                                        <div class="col-lg-4 d-none d-lg-block">
                                            <div class="h-100 px-mm-left" style={{"background-image": `url(java/img/bearings/Clientele/snr1.png)`,height:'100px'}}></div>
                                        </div>
                                        <div class="col-lg-8">
                                            <div class="row px-mm-right">
                                                <div class="col-lg-4">
                                                    <h6 class="mm-title">Company</h6>
                                                    <ul class="mm-link">
                                                        <li><a href="AboutUs">About Us</a></li> 
                                                        <li><a href="Mission&Vision">Mission & Vision</a></li>
                                                      
                                                       
                                                    </ul>
                                                </div>
                                                <div class="col-lg-4">
                                                <h6 class="mm-title">Products</h6>
                                                    <ul class="mm-link">
                                                        <li><a href="StandardProducts">Standard Bearings</a></li>
                                                        <li><a href="NonStandardProducts">Non-Standard Bearings</a></li>
                                                        <h6><a style={{fontWeight: 'bold'}} class="mm-title" href="Services">Services</a></h6>
                                                    </ul>
                                                   
                                                </div>
                                                <div class="col-lg-4">
                                     
                                                    <h6 class="mm-title">Help Center</h6>
                                                    <ul class="mm-link">
                                                       
                                                       
                                                        <li><a href="ContactUs">Contact Us</a></li>
                                                        {/* <li><a href="terms.html">Terms &amp; Conditions</a></li> */}
                                                    </ul>
                                             
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="extra-menu d-flex align-items-center">
                        <div class="d-none d-md-block h-btn m-35px-l">
                            <a class="m-btn m-btn-theme m-btn-radius" style={{backgroundColor:'maroon'}} href="ContactUs">Contact</a>
                        </div>
                        <button type="button" class="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar-collapse-toggle" aria-expanded="false">
                            <span class="icon-bar"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
        )
    }
}

export default Header
