import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <div>
            <footer style={{backgroundColor:'maroon'}} class="theme2nd-bg footer">
        <div class="footer-bottom footer-border-light p-20px-tb">
            <div class="container">
                <p class="m-0px text-center white-color-light"><small>© 2020 SNR Bearings.</small></p>
            </div>
        </div>
    </footer>
            </div>
        )
    }
}

export default Footer
