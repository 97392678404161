import React, { Component } from 'react'

export class NonStandardProducts extends Component {
    render() {
        return (
            <div>
            <section class="section gray-bg" >
            <div class="container">
                <div class="row section-title md-m-25px-b m-45px-b justify-content-center text-center" >
                    <div class="col-lg-8" >
                        <label class="theme-bg-alt p-15px-lr p-5px-tb font-small border-radius-15 theme-color">Our Products</label>
                        <h3 class="display-4 dark-color m-20px-b">Non Standard Bearings</h3>
                        <div class="lead">Following is the list of non-standard bearings we offer</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Air Frame Control Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Air Frame Control Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Ball Transfer Units.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Ball Transfer Units</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Combined Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Combined Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Compressor Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Compressor Bearings</h6>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Eccentric Roller Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Eccentric Roller Bearing</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Full Complement Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Full Complement Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Hex Bore Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Hex Bore Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\High Speed Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">High Speed Bearings</h6>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\High Temperature Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">High temperature Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\One Way Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">One Way Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Sealed Spherical Roller Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Sealed Spherical Roller Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Stainless Steel Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Stainless Steel Bearings</h6>
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\Teflon Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">Teflon Bearings</h6>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 m-15px-tb">
                        <div class="white-bg box-shadow-hover p-25px border-radius-5 transition hover-top">
                            <div>
                                <img src="java\img\bearings\Products\Non Standard\U-V Groove Bearings.png" title="" alt=""/>
                            </div>
                            <h6 class="font-w-700 dark-color m-0px m-5px-b p-15px-t">U V Groove Bearing</h6>
                        </div>
                    </div>

                </div>
            </div>
        </section>
            </div>
        )
    }
}

export default NonStandardProducts
