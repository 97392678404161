import React, { Component } from 'react'


export class Services extends Component {
    render() {
        return (
            <div>
            <section class="section gray-bg">
            <div class="container">
                <div class="row justify-content-center md-m-25px-b m-45px-b">
                    <div class="col-lg-8 light-color text-center">
                        <h1 class="display-4 dark -color">Our Services</h1>
                        <p class="lead m-0px">We are not only dedicated to meeting the diversified needs of our customers, but also offering a wide range of high quality products and providing our best solutions to our customers.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-lg-5 m-15px-tb" style = {{"margin" : 40  }} >
                        <div class="card card-body box-shadow-hover transition hover-top">
                            <div>
                                <img width='400px' src='java\img\bearings\Services\Maintenance Services.png'/>
                            </div>  
                            <h5 class="dark-color font-w-700 p-25px-t m-10px-b">Maintenance Services</h5>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-5 m-15px-tb" style = {{"margin" : 40  }} >
                        <div class="card card-body box-shadow-hover transition hover-top">
                            <div>
                                <img width='400px' src='java\img\bearings\Services\Repair & Reconditioning Solutions.png'/>
                            </div>  
                            <h5 class="dark-color font-w-700 p-25px-t m-10px-b">Repairing and Reconditioning Services</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-lg-5 m-15px-tb" style = {{"margin" : 40  }} >
                        <div class="card card-body box-shadow-hover transition hover-top">
                            <div>
                                <img width='400px' src='java\img\bearings\Services\On-Site Seminars & Training Sessions.png'/>
                            </div>  
                            <h5 class="dark-color font-w-700 p-25px-t m-10px-b">Seminars and Training Sessions</h5>
                        </div>
                    </div>
                    <div class="col-sm-4 col-lg-5 m-15px-tb" style = {{"margin" : 40  }} >
                        <div class="card card-body box-shadow-hover transition hover-top">
                            <div>
                                <img width='500px' height="210px" src='java\img\bearings\Services\Technical Support.png'/>
                            </div>  
                            <h5 class="dark-color font-w-700 p-25px-t m-10px-b">Technical Support</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            </div>
        )
    }
}

export default Services
